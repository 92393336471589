@import 'primeng/resources/themes/bootstrap4-light-blue/theme.css';

:root {
  --amplify-components-button-primary-background-color: #000000 !important;
  --amplify-components-button-primary-hover-background-color: #374151 !important;
  --amplify-components-button-link-color: #000000 !important;
  --amplify-components-button-link-hover-background-color: #c3c6c7 !important;
  --amplify-components-button-hover-background-color: #c3c6c7 !important;
}

.p-paginator .p-paginator-current {
  color: #000;
}

.p-component {
  font-size: 14.4px;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.75rem;
}

.p-datatable .p-datatable-tfoot > tr > td {
  padding: 0.75rem;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.75rem;
}
