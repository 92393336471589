@import 'primeng/resources/themes/bootstrap4-light-blue/theme.css';

.p-paginator .p-paginator-current {
  color: #000;
}

.p-component {
  font-size: 14.4px;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.75rem;
}

.p-datatable .p-datatable-tfoot > tr > td {
  padding: 0.75rem;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.75rem;
}
